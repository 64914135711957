import React from "react";
import ScrollAnimation from 'react-animate-on-scroll';
import ScrollableAnchor from 'react-scrollable-anchor'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import Box from '@material-ui/core/Box';

import CodeIcon from '@material-ui/icons/Code';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import BrushIcon from '@material-ui/icons/Brush';

import salyImg from '../../../data/Saly.png';
import saly2Img from '../../../data/Saly2.png';
import saly3Img from '../../../data/Saly3.png';

const Benefits = () => {
	return (
		<ScrollableAnchor id={'sequoia'}>
			<Box mb={12}>
				<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
					<Grid container spacing={2} align="center" style={{marginTop: 10}}>
						<Grid item md={12} sm={12} xs={12}>
							<Typography variant="h3" align="center" component="h2" gutterBottom>
								Why Choose sequoia?
							</Typography>
						</Grid>
					</Grid>
					<Grid container spacing={2} justify="center" style={{ marginTop: 40 }}>
						<Grid item xs={12} sm={3} md={3} align="center" style={{border: '1px solid #E5E5E5', borderRadius: 4}}>
							<img alt="headerImg" src={salyImg} width="40%" />
							<Typography variant="h5" align="center" component="h5" gutterBottom style={{ marginTop: 20 }}>
								Customizable
							</Typography>
							<Typography variant="subtitle2" align="center" component="p" gutterBottom style={{ marginTop: 20, color: '#6C6C6C82', fontWeight: 'bold' }}>
								Fast and Inexpensive
							</Typography>
							<Typography variant="subtitle1" align="center" component="p" gutterBottom style={{ marginTop: 20 }}>
								Sequoia Green  offers the fastest payments: for almost nothing.
							</Typography>
						</Grid>
						<Grid item sm={1} > </Grid>
						<Grid item xs={12} sm={3} md={3} align="center" style={{border: '1px solid #E5E5E5', borderRadius: 4}}>
							<img alt="headerImg" src={saly2Img} width="40%" />
							<Typography variant="h5" align="center" component="h5" gutterBottom style={{ marginTop: 20 }}>
								Learn
							</Typography>
							<Typography variant="subtitle2" align="center" component="p" gutterBottom style={{ marginTop: 20, color: '#6C6C6C82', fontWeight: 'bold' }}>
								Turnkey or In-house
							</Typography>
							<Typography variant="subtitle1" align="center" component="p" gutterBottom style={{ marginTop: 20 }}>
								Quick, simple setup. Or with a custom fit, shape Sequoia Green  to fit your business needs.
							</Typography>
						</Grid>
						<Grid item sm={1} > </Grid>
						<Grid item xs={12} sm={3} md={3} align="center" style={{border: '1px solid #E5E5E5', borderRadius: 4}}>
							<img alt="headerImg" src={saly3Img} width="40%" />
							<Typography variant="h5" align="center" component="h5" gutterBottom style={{ marginTop: 20 }}>
								For Everyone
							</Typography>
							<Typography variant="subtitle2" align="center" component="p" gutterBottom style={{ marginTop: 20, color: '#6C6C6C82', fontWeight: 'bold' }}>
								Startup to Enterprise
							</Typography>
							<Typography variant="subtitle1" align="center" component="p" gutterBottom style={{ marginTop: 20 }}>
								Large or small, Sequoia Green scales with your business no matter the size.
							</Typography>
						</Grid>
	                </Grid>
				</ScrollAnimation>
			</Box>
		</ScrollableAnchor>
	);
}

export default Benefits;
