import React, {useState, useRef, useEffect} from "react";
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Hidden from '@material-ui/core/Hidden';

import logo from '../../../data/logo.svg';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TelegramIcon from '@material-ui/icons/Telegram';
import TwitterIcon from '@material-ui/icons/Twitter';

import { withStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import discordImg from '../../../data/discord.svg';
import twitterImg from '../../../data/twitter.svg';
import instagramImg from '../../../data/instagramm.svg';
import telegramImg from '../../../data/telegramm.svg';
import youtubeImg from '../../../data/youtube.png';

const SocialButton = withStyles(theme => ({
	root: {
		paddingLeft: 5,
		paddingRight: 5,
		minHeight: 0,
		minWidth: 0,
		borderRadius: 3
	},
	contained: {
		color: theme.palette.primary.main,
		background: theme.palette.primary.transparent.light,
		'&:hover': {
			background: theme.palette.primary.transparent.dark,
		},
	},
}))(Button);

const Footer = () => {

	return (
		<Container>
			<Box textAlign={"center"} my={5} fontWeight={200}>
				<Grid container align="center" justify="center">
					<Grid item xs={12} sm={12} md={3} style={{ display: "grid" }}>
						<Hidden mdDown>
							<a href="#header">
								<img alt="logo" src={logo} width="30%" style={{ marginBottom: 10 }}/>
							</a>
						</Hidden>
						<Button variant="contained" href="https://uniswap.org/" target="_blank" color="primary" disableElevation style={{ color: '#05642F', textTransform: 'uppercase', border: '1px solid #E4E4E4', borderRadius: 4}}>
							Subscribe Now
						</Button>
					</Grid>
					<Hidden smDown>
						<Grid item md={2} style={{ marginTop: 25 }}>
							<Typography variant="h6" component="h4" style={{ marginBottom: 25 }}>
								Learn more
							</Typography>
							<Link variant="contained" href="https://uniswap.org/" target="_blank" color="secondary">
								<Typography>
									Whitepaper
								</Typography>
							</Link>
							<Link variant="contained" href="https://discord.com/channels/956631525657542727/956631526697734256/" target="_blank" color="secondary">
								<Typography>
									Support
								</Typography>
							</Link>
						</Grid>
						<Grid item md={2} style={{ marginTop: 25 }}>
							<Typography variant="h6" component="h4" style={{ marginBottom: 25 }}>
								A Company
							</Typography>
							<Link variant="contained" href="https://ropsten.etherscan.io/address/0x7460b7baee340c15e9150915fa7da794c8c43857/" target="_blank" color="secondary">
								<Typography>
									Explorer
								</Typography>
							</Link>
							<Link variant="contained" href="https://discord.com/channels/956631525657542727/956631526697734256/" target="_blank" color="secondary">
								<Typography>
									Support
								</Typography>
							</Link>
							<Link variant="contained" href="https://medium.com/" target="_blank" color="secondary">
								<Typography>
									Press
								</Typography>
							</Link>
						</Grid>
					</Hidden>
					<Grid item xs={12} sm={12} md={5} align={{ xs: "center", md: "right" }} style={{ marginTop: 25 }}>
						<Typography variant="h6" component="h4" style={{ marginBottom: 25 }}>
							Contact us
						</Typography>
						<Grid container align="center" justify="center" spacing={6}>
							<Grid item>
								<Link href="https://discord.com/channels/956631525657542727/956631526697734256/" color="secondary">
									<img alt="discordImg" src={discordImg} width="40" />
									<Typography variant="subtitle1" component="p" gutterBottom>
										Discord
									</Typography>
								</Link>
							</Grid>
							<Grid item>
								<Link href="https://twitter.com/SequoiaToken/" color="secondary">
									<img alt="twitterImg" src={twitterImg} width="40" />
									<Typography variant="subtitle1" component="p" gutterBottom>
										Twitter
									</Typography>
								</Link>
							</Grid>
							<Grid item>
								<Link href="https://www.instagram.com/sequoiagreentoken/" color="secondary">
									<img alt="instagramImg" src={instagramImg} width="40" />
									<Typography variant="subtitle1" component="p" gutterBottom>
										Instagram
									</Typography>
								</Link>
							</Grid>
							<Grid item>
								<Link href="https://uniswap.org/" color="secondary" alignItems="center">
									<img alt="telegramImg" src={telegramImg} width="40" />
									<Typography variant="subtitle1" component="p" gutterBottom>
										Telegram
									</Typography>
								</Link>
							</Grid>
							<Grid item>
								<Link href="https://www.youtube.com/channel/UCfowoMGfvDfpJShwQersFzg/" color="secondary">
									<img alt="youtubeImg" src={youtubeImg} width="40" />
									<Typography variant="subtitle1" component="p" gutterBottom>
										Youtube
									</Typography>
								</Link>
							</Grid>
						</Grid>
					</Grid>
					<Hidden mdUp>
						<Grid item xs={6} sm={6} style={{ marginTop: 25 }}>
							<Typography variant="h6" component="h4" style={{ marginBottom: 25 }}>
								Learn more
							</Typography>
							<Link variant="contained" href="https://uniswap.org/" target="_blank" color="secondary">
								<Typography>
									Whitepaper
								</Typography>
							</Link>
							<Link variant="contained" href="https://discord.com/channels/956631525657542727/956631526697734256/" target="_blank" color="secondary">
								<Typography>
									Support
								</Typography>
							</Link>
						</Grid>
						<Grid item xs={6} sm={6} style={{ marginTop: 25 }}>
							<Typography variant="h6" component="h4" style={{ marginBottom: 25 }}>
								A Company
							</Typography>
							<Link variant="contained" href="https://ropsten.etherscan.io/address/0x7460b7baee340c15e9150915fa7da794c8c43857/" target="_blank" color="secondary">
								<Typography>
									Explorer
								</Typography>
							</Link>
							<Link variant="contained" href="https://discord.com/channels/956631525657542727/956631526697734256/" target="_blank" color="secondary">
								<Typography>
									Support
								</Typography>
							</Link>
							<Link variant="contained" href="https://medium.com/" target="_blank" color="secondary">
								<Typography>
									Press
								</Typography>
							</Link>
						</Grid>
					</Hidden>
				</Grid>
			</Box>
		</Container>
	);
}

export { Footer };
