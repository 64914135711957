import React from "react";
import ScrollableAnchor from 'react-scrollable-anchor';
import ScrollAnimation from 'react-animate-on-scroll';
import { Parallax } from 'react-scroll-parallax';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import { makeStyles } from '@material-ui/core/styles';

import headerImg from '../../../data/header.svg';
import buyImg from '../../../data/buy.svg';
import whitepaper from '../../../data/SequoiaGreenToken-Whitepaper.pdf';

const useStyles = makeStyles((theme) => ({
  shape: {
  	zIndex: "-1",
    [theme.breakpoints.down('xs')]: {
      visibility: "hidden"
    },
    [theme.breakpoints.down('md')]: {
      opacity: "0.4"
    }
  },
}));

const Header = () => {
	const classes = useStyles();

	return (
		<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
			<Box height="100vh" pt={6} display="flex" justify="center" textAlign="center" style={{ background: `url(${headerImg})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
				<Grid container alignItems="center" justify="center" spacing={2} >
					<Grid item xs={12} md={6}>
						<Typography variant="h2" component="h2">
                            Digital <span style={styles.highlighter} >cash</span> for the world
						</Typography>
						<Typography variant="subtitle1" component="p" gutterBottom>
                            Low fee micro-transactions, large business transaction, and permissionless spending.
						</Typography>
						<Button variant="contained" href={whitepaper} target="_blank" color="primary" disableElevation style={{marginTop: 25, color: '#05642F', textTransform: 'uppercase', border: '1px solid #E4E4E4', borderRadius: 4}}>
							Get Started
						</Button>
					</Grid>
				</Grid>
			</Box>
		</ScrollAnimation>
	);
}

const styles = {
	highlighter: {
		color: "#05642F",
		paddingLeft: 5,
		paddingRight: 5
	}
}

export default Header;
