import React, { useState } from "react";
import ScrollAnimation from 'react-animate-on-scroll';
import ScrollableAnchor from 'react-scrollable-anchor';
import { Parallax } from 'react-scroll-parallax';
import { TelegramClient } from 'messaging-api-telegram';

import { makeStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';

import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TelegramIcon from '@material-ui/icons/Telegram';
import TwitterIcon from '@material-ui/icons/Twitter';

import { withStyles } from '@material-ui/core/styles';

import sapiensImg from '../../../data/sapiens.svg';
import sapiens2Img from '../../../data/sapiens2.svg';
import sapiens3Img from '../../../data/sapiens3.svg';

const SocialButton = withStyles(theme => ({
	root: {
        marginLeft: 5,
        marginRight: 5,
		paddingLeft: 5,
		paddingRight: 5,
		minHeight: 0,
		minWidth: 0,
		borderRadius: 3
	},
	contained: {
		color: theme.palette.primary.main,
		background: theme.palette.primary.transparent.light,
		'&:hover': {
			background: theme.palette.primary.transparent.dark,
		},
	},
}))(Button);

const useStyles = makeStyles((theme) => ({
  shape: {
      zIndex: "-1",
    [theme.breakpoints.down('md')]: {
      opacity: "0.4"
    }
  },
}));

const ContactUs = () => {
    const classes = useStyles();

    return (
        <ScrollableAnchor id={'journey'}>
            <Box my={12} mx={3}>
                <ScrollAnimation animateIn="fadeIn" animateOnce={true} delay={200}>
                    <Grid container spacing={2} style={{marginTop: 10}} justify="center">
                        <Grid item md={7} xs={12} sm={12} align="center">
                            <Typography variant="h3" align="center" component="h2" gutterBottom>
                                Begin your Sequoia Green Journey
                            </Typography>
                        </Grid>
						<Grid container align="center" alignItems="center" justify="center" spacing={2} >
							<Grid item xs={12} md={4}>
								<Typography variant="h4" component="h3">
	                                Learn
								</Typography>
								<Typography variant="subtitle1" component="p" gutterBottom>
									Start with the basics. What is Sequoia Green ? How is it used? How is it different than Bitcoin?
								</Typography>
								<Button variant="contained" href="https://medium.com/" target="_blank" color="primary" disableElevation style={{marginTop: 25, color: '#05642F', textTransform: 'uppercase', border: '1px solid #E5E5E5'}}>
									The basics
								</Button>
							</Grid>
							<Grid item md={5} xs={12} sm={8}>
								<img alt="headerImg" src={sapiensImg} width="80%" />
							</Grid>
						</Grid>
						<Grid container align="center" alignItems="center" justify="center" spacing={2} >
							<Hidden smDown>
								<Grid item md={6}>
									<img alt="headerImg" src={sapiens2Img} width="80%" />
								</Grid>
							</Hidden>
							<Grid item xs={12} md={4}>
								<Typography variant="h4" component="h3">
	                                Purchase Sequoia Green
								</Typography>
								<Typography variant="subtitle1" component="p" gutterBottom>
									The quickest and easiest way to acquire Sequoia Green  is to visit an exchange. We can point you in the right direction.
								</Typography>
								<Button variant="contained" href="https://uniswap.org/" target="_blank" color="primary" disableElevation style={{marginTop: 25, color: '#05642F', textTransform: 'uppercase', border: '1px solid #E5E5E5'}}>
									Exchange
								</Button>
							</Grid>
							<Hidden mdUp>
								<Grid item xs={12} sm={8}>
									<img alt="headerImg" src={sapiens2Img} width="80%" />
								</Grid>
							</Hidden>
						</Grid>
						<Grid container align="center" alignItems="center" justify="center" spacing={2} >
							<Grid item xs={12} md={4}>
								<Typography variant="h4" component="h3">
	                                Get A Wallet
								</Typography>
								<Typography variant="subtitle1" component="p" gutterBottom>
									You don’t need a wallet to own Sequoia Green , but it offers additional security and control and some added benefits as well. We recommend using one.
								</Typography>
								<Button variant="contained" href="" target="_blank" color="primary" disabled disableElevation style={{marginTop: 25, color: '#05642F', textTransform: 'uppercase', border: '1px solid #E5E5E5'}}>
									Wallets
								</Button>
							</Grid>
							<Grid item md={6} xs={12} sm={8}>
								<img alt="headerImg" src={sapiens3Img} width="80%" />
							</Grid>
						</Grid>
                    </Grid>
                </ScrollAnimation>
            </Box>
        </ScrollableAnchor>
    );
}

const styles = {
	highlighter: {
		fontWeight: "bold"
	}
}

export default ContactUs;
