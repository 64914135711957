import React from "react";
import ScrollAnimation from 'react-animate-on-scroll';
import ScrollableAnchor from 'react-scrollable-anchor'
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import { Parallax } from 'react-scroll-parallax';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import contentImg from '../../../data/content.svg';
import videoImg from '../../../data/video.svg';
import musicImg from '../../../data/music.svg';
import gamingImg from '../../../data/gaming.svg';
import podcastsImg from '../../../data/podcasts.svg';
import artImg from '../../../data/arts.svg';

const Clients = () => {

	return (
		<ScrollableAnchor id={'content'}>
            <Box mb={12}>
                <ScrollAnimation animateIn="fadeIn" animateOnce={true} delay={150}>
                    <Grid container spacing={2} align="center" style={{marginTop: 10}}>
                        <Grid item md={12} sm={12} xs={12}>
                            <Typography variant="h3" align="center" component="h2" gutterBottom>
                                Supported content type
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} align="center" justify="center" style={{ marginTop: 40 }}>
                        <Grid item>
                            <img alt="contentImg" src={contentImg} width="72"/>
                            <Typography variant="subtitle1" align="center" component="p" gutterBottom style={{ marginTop: 20 }}>
                                Written content
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>	</Grid>
                        <Grid item>
                            <img alt="videoImg" src={videoImg} width="80%" />
                            <Typography variant="subtitle1" align="center" component="p" gutterBottom style={{ marginTop: 20 }}>
                                Video
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>	</Grid>
                        <Grid item>
                            <img alt="musicImg" src={musicImg} width="80%" />
                            <Typography variant="subtitle1" align="center" component="p" gutterBottom style={{ marginTop: 20 }}>
                                Music
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>	</Grid>
                        <Grid item>
                            <img alt="gamingImg" src={gamingImg} width="80%" />
                            <Typography variant="subtitle1" align="center" component="p" gutterBottom style={{ marginTop: 20 }}>
                                Gaming
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>	</Grid>
                        <Grid item>
                            <img alt="podcastsImg" src={podcastsImg} width="80%" />
                            <Typography variant="subtitle1" align="center" component="p" gutterBottom style={{ marginTop: 20 }}>
                                Podcasts
                            </Typography>
                        </Grid>
						<Grid item xs={1}>	</Grid>
						<Grid item>
                            <img alt="artImg" src={artImg} width="80%" />
                            <Typography variant="subtitle1" align="center" component="p" gutterBottom style={{ marginTop: 20 }}>
                                Art
                            </Typography>
                        </Grid>
                    </Grid>
                </ScrollAnimation>
		   </Box>
		</ScrollableAnchor>
	);
}

export default Clients;
