import React, {useState} from "react";
import { goToAnchor } from 'react-scrollable-anchor'

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';
import Slide from '@material-ui/core/Slide';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';

import MenuIcon from '@material-ui/icons/Menu';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import GroupIcon from '@material-ui/icons/Group';
import CodeIcon from '@material-ui/icons/Code';
import FolderIcon from '@material-ui/icons/Folder';
import EmailIcon from '@material-ui/icons/Email';

import logo from '../../../data/logo.svg';
import buy from '../../../data/buy.svg';
import whitepaper from '../../../data/SequoiaGreenToken-Whitepaper.pdf';

import { withStyles } from '@material-ui/core/styles';

const MenuButton = withStyles(theme => ({
    root: {
        padding: 0,
        paddingLeft: 5,
        paddingRight: 5,
        margin: 5,
        marginLeft: 10,
        minHeight: 0,
        minWidth: 0,
        marginRight: 10,
        fontSize: 16,
        letterSpacing: "0.75px",
        color: "#2C2C2C"
    },
}))(Button);

function HideOnScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({ target: window ? window() : undefined });

    return (
    <Slide appear={false} direction="down" in={!trigger}>
        {children}
    </Slide>
    );
}


const Nav = (props) => {
    const [showDrawer, setShowDrawer] = useState(false);

    const goToElement = (element) => {
        goToAnchor(element);
        setShowDrawer(false);
    }

    const goToLink = (link) => {
        window.location.href = link;
        setShowDrawer(false);
    }

    return (
        <HideOnScroll {...props}>
            <AppBar color="inherit" elevation={0} style={{ paddingTop: 10, paddingBottom: 10 }}>
                <Container>
                    <Toolbar>
                        <Hidden smDown>
                            <Grid container alignItems="center">
                                <Grid item>
                                    <a href="#header">
                                        <img alt="logo" src={logo} width="100%" />
                                    </a>
                                </Grid>
                                <Grid item md align="center">
                                    <MenuButton href="https://ropsten.etherscan.io/address/0x7460b7baee340c15e9150915fa7da794c8c43857/" target="_blank">
                                        Explorer
                                    </MenuButton>
                                    <MenuButton href="#content">
                                        Support
                                    </MenuButton>
                                    <MenuButton href="#journey">
                                        Press
                                    </MenuButton>
                                </Grid>
                            </Grid>
                            <Grid item md align="right">
                                <Button variant="contained" href={whitepaper} target="_blank" color="primary" disableElevation style={{ color: '#05642F', textTransform: 'uppercase', border: '1px solid #E4E4E4', borderRadius: 4}}>
                                    Get Started
                                </Button>
                            </Grid>
                        </Hidden>
                        <Hidden mdUp>
                            <Grid container alignItems="center">
                                <Grid item>
                                    <a href="#header">
                                        <img alt="logo" src={logo} width="100%" />
                                    </a>
                                </Grid>
                                <Grid item sm xs align="right">
                                    <IconButton
                                        edge="start"
                                        color="red"
                                        aria-label="open navigation"
                                        onClick={() => setShowDrawer(true)}
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Hidden>
                    </Toolbar>
                </Container>
                <Drawer anchor={"center"} open={showDrawer} onClose={() => setShowDrawer(false)} style={{background: 'transparent'}}>
                    <List style={{width: "100vw"}}>
                        <ListItem button onClick={() => goToLink("https://ropsten.etherscan.io/address/0x7460b7baee340c15e9150915fa7da794c8c43857/")}>
                            <ListItemText primary={"Explorer"} />
                        </ListItem>
                        <ListItem button onClick={() => goToElement("#content")}>
                            <ListItemText primary={"Support"} />
                        </ListItem>
                        <ListItem button onClick={() => goToElement("#journey")}>
                            <ListItemText primary={"Press"} />
                        </ListItem>
                        <Divider />
                        <ListItem button onClick={() => goToLink("https://discord.com/channels/956631525657542727/956631526697734256/")}>
                            <ListItemText primary={"Get Support"} />
                        </ListItem>
                    </List>
                </Drawer>
            </AppBar>
        </HideOnScroll>
    );
}

export default Nav;
